import axios from "axios";
import URL from "axios";

const instance = axios.create({
  // ...(( process.env.NODE_ENV !== 'production') && {baseURL: 'http://localhost:3001/api/v1'}),
  // ...(( process.env.NODE_ENV === 'production') && {baseURL: 'https://www.alpaca-assessment.com/api/v1'})
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

/**
 * Add JWT token to all request headers
 */
instance.interceptors.request.use(
  (request) => {
    const token = localStorage.getItem("token");

    if (typeof token !== "undefined") {
      request.headers.authorization = `Bearer ${token}`;
    }

    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * Add interceptor to handle network errors
 * Interceptor will log the error and attempt the request again
 *
 * See https://javascript.plainenglish.io/how-to-retry-requests-using-axios-64c2da8340a7
 */
instance.interceptors.response.use(
  (response) => {
    // We got a valid response so proceed as normal
    return response;
  },
  (error) => {
    // destructure the error to get convenient access to useful information
    const { config, request, response } = error;

    // Test to see if we should bother retrying or not.
    // When config.retry gets to 0 this test will fail and the error will be
    // passed back up the original API call to handle (and passed back to the Component to handle)
    // console.log("Interceptor - retry: ", config.retry);
    if (!config || !config.retry) {
      console.log(
        "Interceptor - finished trying the call, nothing more we can do"
      );
      return Promise.reject(error);
    }

    // console.log("Interceptor error: " + error.message);
    // console.log("Interceptor Request", request);
    // console.log("Interceptor Response", response);

    if (response) {
      // Response exists so the API must have returned some sort of error such as a 5xx or 4xx
      // No point trying the call again.

      // Maybe we can do something with the error codes at a later date?
      // const errorLog = {
      //   studentId: localStorage.getItem("studentId"),
      //   classId: localStorage.getItem("classId"),
      //   url: window.location.href,
      //   errorMessage: JSON.stringify(response),
      //   deviceType: localStorage.getItem("deviceType"),
      // };
      // logErrors(errorLog);
      return Promise.reject(error);
    } else if (request) {
      // If we've gotten to here then there was no response from the server, must be a network error or some sort

      // console.log("Interceptor - network error, lets try the call again");

      config.retry -= 1;
      const delayRetryRequest = new Promise((resolve) => {
        setTimeout(() => {
          // console.log("retry the request", config.url);
          resolve();
        }, config.retryDelay || 1000);
      });
      return delayRetryRequest.then(() => instance(config));
    } else {
      // console.log("Interceptor - something else happened!");
    }
  }
);

/**
 * Simple handler for all GET requests to simplify the configuration of
 * the interceptor. All this does is add the configuration for the number
 * of retries and the delay between retries. We don't want to clutter the
 * rest of the code by repeating this config everywhere.
 *
 * Need to generalise this so that it can also be used for POST requests
 *
 * @param {*} path
 * @returns
 */
function get(path) {
  return instance.get(path, { retry: 3, retryDelay: 3000 }).then((response) => {
    return response;
  });
}

/**
 * Authenticate user with server using email and password
 *
 * @param {*} id
 * @param {*} password
 * @returns JWT token that should be used to authenticate future requests
 */
export function login(id, password) {
  return instance
    .post("/login", { email: id, password: password })
    .then((response) => {
      return response.data;
    });
}

export function qrlogin(qrdata) {
  // The QR code might contain the url for the app so strip that out of the data first
  let url = process.env.REACT_APP_BASE_URL + "/?code=";
  let data = qrdata.replace(url, "");
  // if (window.location.hostname.includes('localhost')){
  //   // for testing
  //   data = qrdata.replace('http://localhost:3000/?code=', '');
  // } else if (window.location.hostname.includes('staging')) {
  //   // for staging
  //   data = qrdata.replace('https://staging.learner.alpaca-assessment.com/?code=', '');
  // } else {
  //   // for production
  //   data = qrdata.replace('https://learner.alpaca-assessment.com?code=', '');
  // }

  // parse QR code data to extract username and password
  if (data.length > 32) {
    const uuid = data.slice(0, 32);
    const formattedUUID =
      data.slice(0, 8) +
      "-" +
      data.slice(8, 12) +
      "-" +
      data.slice(12, 16) +
      "-" +
      data.slice(16, 20) +
      "-" +
      data.slice(20, 32);
    const username = formattedUUID + "@alpaca.com";
    const password = data.slice(32, data.length);

    // // console.log(uuid);
    // // console.log(formattedUUID);
    // // console.log(password);

    // call login API
    return instance
      .post("/students/login", { email: username, password: password })
      .then((response) => {
        return response.data;
      });
  }
}

/**
 * Retrieve unique identifier for authenticated user
 *
 * @returns UUID for currently logged in user
 */
export function whoami() {
  return get("/whoAmI").then((response) => {
    return response;
  });
}

/**
 *
 * @param {*} studentid
 * @returns
 */
export function getAssessment(studentid) {
  return get(`/students/${studentid}/assessments`).then((response) => {
    return response.data;
  });
}

/**
 *
 * @param {*} studentid
 * @param {*} assessmentid
 * @returns
 */
export function getTask(studentid, assessmentid) {
  return get(`/students/${studentid}/assessments/${assessmentid}/tasks`).then(
    (response) => {
      return response.data;
    }
  );
}

/**
 *
 * @param {*} studentid
 * @param {*} assessmentid
 * @returns
 */
export function getAllTask(studentid, assessmentid) {
  return get(
    `/students/${studentid}/assessments/${assessmentid}/all-tasks`
  ).then((response) => {
    return response.data;
  });
}

/**
 * Post student answers for a specific task to the server
 *
 * @param {*} studentid
 * @param {*} assessmentid
 * @param {*} taskid
 * @param {*} results
 */
export function saveTaskAnswers(studentid, assessmentid, taskid, results) {
  return instance.post(
    `/students/${studentid}/assessments/${assessmentid}/tasks/${taskid}/answers`,
    results
  );
}

export function logErrors(error) {
  return instance.post(`/log-errors`, error);
}

/**
 * Attempt to refresh the JWT token using a refresh token.
 *
 * @param {string} refreshToken - The refresh token to use for token refresh.
 * @returns {Promise<string>} A Promise that resolves with the new JWT token if successful.
 */
export function refreshToken(refreshToken) {
  return instance
    .post("/refresh", { refreshToken }) // Replace with your actual refresh endpoint
    .then((response) => {
      // Check if the response contains the new access token
      const newAccessToken = response.data.accessToken;

      if (newAccessToken) {
        // Update the token in local storage with the new access token
        localStorage.setItem("token", newAccessToken);
        return newAccessToken; // Resolve the Promise with the new access token
      } else {
        // If the response does not contain a new access token, reject the Promise
        return Promise.reject(new Error("Token refresh failed"));
      }
    })
    .catch((error) => {
      // Handle any errors that occurred during the token refresh
      return Promise.reject(error);
    });
}
